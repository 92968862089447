import React from 'react'
import PingTeamOnSlack from "./PingTeamOnSlack";
import PingCommManager from "./PingCommManager";
import IncidentManagerToolAction from "./IncidentManagerToolAction";

const IncidentManagerTools = ({teams, crisisId}) => {
  return (
    <table>
      <tbody>
        <IncidentManagerToolAction label="Ping teams on Slack:">
          <PingTeamOnSlack teams={teams} crisisId={crisisId}/>
        </IncidentManagerToolAction>
        <IncidentManagerToolAction label="Ping communication manager:">
          <PingCommManager crisisId={crisisId}/>
        </IncidentManagerToolAction>
      </tbody>
    </table>
  )
}

export default IncidentManagerTools