import React from 'react'
import Select from 'react-select'
import {useState} from 'react'

const csrfToken = () => {
  let meta = document.querySelector('meta[name="csrf-token"]')
  return meta ? meta.getAttribute('content') : null
}

const httpHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken()
  }
}

const PingTeamOnSlack = ({teams, crisisId}) => {
  const options = teams.map(({id, name}) => ({value: id, label: name}))
  const [teamsToPing, setTeamsToPing] = useState([])
  const [notifying, setNotifying] = useState(false)

  const handleSubmit = () => {
    setNotifying(true)
    fetch('/slack_notifier/ping_teams', {
      method: 'POST',
      headers: httpHeaders(),
      body: JSON.stringify({
        teams: teams
          .filter((team) => teamsToPing.find((teamToPing) => teamToPing.value === team.id))
          .map(({id, handle}) => ({id, handle})), crisis_id: crisisId}),
    }).then(() => {
      setNotifying(false)
      setTeamsToPing([])
    })
  }

  const handleChange = (teams) => {
    setTeamsToPing(teams)
  }

  return (
    <div className="flex flex-row items-center gap-2">
      <Select
        options={options}
        onChange={handleChange}
        isMulti={true}
        value={teamsToPing}
        isDisabled={notifying}
        className="w-full"
      />
      <button disabled={notifying} className="button bg-blue-600 hover:bg-blue-500 text-white" onClick={handleSubmit}>
        Ping
      </button>
    </div>
  )
}

export default PingTeamOnSlack