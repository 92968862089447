import React from "react"
import Minute from "../modules/Minute"
import MinuteView from "./MinuteView"
import MinuteEditPopin from "./MinuteEditPopin"
import MinuteEdit from "./MinuteEdit"
import { focus, scrollTo, stripHtml } from "../modules/Utility"
import useVisibility from '../hooks/useVisibility'
import { Remarkable } from 'remarkable'

const PLAIN_TEXT = new Remarkable('full', { html: false })

const showBrowserNotification = (minute) => {
  const notify = () => {
    const body = stripHtml(PLAIN_TEXT.render(minute.what))
    new Notification(`${minute.who}: ${body}`)
  }

  if ('Notification' in window) {
    if (Notification.permission === 'granted') {
      notify()
    } else {
      Notification.requestPermission(() => {
        notify()
      })
    }
  }
}


const scrollToBottom = () => {
  window.setTimeout(
    () => {
      focus("whoInput")
      window.setTimeout(() => {
        scrollTo('bottomScrollAnchor')
      }, 100)
    },
    300
  )
}

const initialArg = (list) => ({ editRef: React.createRef(), minutes: list.map((h) => new Minute(h)), who: '', details: '', what: '' })

const reducer = (state, action) => {
  switch (action.type) {
    case 'APPEND':
      return { ...state, minutes: [...state.minutes, new Minute(action.minute)] }
    case 'UPDATE':
      return {
        ...state,
        minutes: state.minutes.map(
          (minute) => minute.id == action.minute.id ? new Minute(action.minute) : minute
        )
      }
    case 'DESTROY':
      return { ...state, minutes: state.minutes.filter((e) => e.id != action.minute.id) }
    default:
      return state
  }
}

const Logbook = ({ crisis_name, id, list, scribe, show_input = true}) => {
  const [state, dispatch] = React.useReducer(reducer, initialArg(list))
  const [editedMinute, setEditedMinute] = React.useState({})
  const [showPopin, setShowPopin] = React.useState(false)
  const visible = useVisibility()
  const onReceived = React.useRef()

  const whos = Array.from(new Set(state.minutes.map((minute) => minute.who)))

  const processMessage = ({ type, minute }) => {
    switch (type) {
      case 'create':
        dispatch({ type: 'APPEND', minute })
        show_input && scrollToBottom()
        if(!visible) {
          showBrowserNotification(minute)
        }
        break
      case 'update':
        dispatch({ type: 'UPDATE', minute })
        break
      case 'discard':
        dispatch({ type: 'DESTROY', minute })
        break
    }
  }

  React.useEffect(() => {
    onReceived.current = processMessage;
  }, [processMessage])

  React.useEffect(() => {
    show_input && scrollToBottom()

    const channel = App.cable.subscriptions.create(
      { channel: "ScribeTranscriptChannel", crisis_id: id },
      { received: (minute) => onReceived.current(minute) },
    )

    return () => channel.unsubscribe()
  }, [])

  const edit = (minute) => {
    setEditedMinute(minute)
    setShowPopin(true)
  }

  return (
    <>
      <MinuteEditPopin show={showPopin} onClose={() => setShowPopin(false)} minute={editedMinute} crisisId={id} onSave={(minute) => dispatch({ type: 'UPDATE', minute })} />
      <div className="dc-container py-3 sm:px-6 lg:px-8 what">
        <h1 className="text-gray-700 text-xl py-3">
          Logbook for {crisis_name} (<a href={`/crises/${id}`} className="text-blue-500 hover:underline">#{id}</a>)<br/>
          { scribe && <small className="text-sm mr-6">Scribe: {scribe}</small>}
          { !show_input && <a href={`/crises/${id}/minutes`} className="text-sm text-blue-500 hover:underline">Edit Logbook</a>}
        </h1>
        <div className="w-full">
          {!state.minutes.length ? (
            <div className="max-w-md bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4" role="alert">
              <p>No message recorded for this incident</p>
            </div>
          ) : state.minutes.map(
            (minute) => (
              <MinuteView
                key={minute.id}
                minute={minute}
                onEdit={edit}
              />
            )
          )}
        </div>
        <div id="bottomScrollAnchor" style={{ float: "left", clear: "both" }}></div>
      </div>
      {show_input && <footer className="footer bg-gray-100">
        <div className="dc-container sm:px-6 lg:px-8">
          <MinuteEdit crisis_id={id} whos={whos}/>
        </div>
      </footer>
      }
    </>
  )
}

export default Logbook
