import React from 'react'
import classnames from 'classnames'

const IncidentManagerCard = ({ manager: { name, email, phone_number, vacation_ends_at }, kind }) => {
  return (
    <div className="max-w-sm flex-col rounded items-center text-sm border-2 p-3 my-1 ">
      <p className="text-gray-900 leading-none font-semibold text-lg pb-2 flex justify-between">
        <span>{name}</span>
        {vacation_ends_at && new Date() < new Date(vacation_ends_at) && <span className="text-xs text-gray-500">OFF</span>}
      </p>
      <p className="text-gray-800 font-normal">
        <span>Phone:</span>&nbsp;<a href={`tel:${phone_number}`}>{phone_number}</a>
      </p>
      <p className="text-gray-800 font-normal">
        <span>Email:</span>&nbsp;<a href={`mailto:${email}`}>{email}</a>
      </p>
    </div>
  )
}

export default IncidentManagerCard
