import React from 'react'
import classnames from 'classnames'
import moment from 'moment'
import { Remarkable } from 'remarkable'

const MARKDOWN = new Remarkable('full')

const MinuteView = ({ minute, onEdit }) => {
  const [displayActions, setDisplayActions] = React.useState(false)

  const handleMarkImportantClick = () => minute.update({ important: !minute.important })

  const handleDestroyClick = () => {
    let r = confirm(`Delete "${minute.who}: ${minute.what}"?`)
    if (r == true) {
      minute.destroy()
    }
  }

  return (
    <div
      className={classnames('minute-view flex flex-col sm:flex-row py-2 rounded border-b relative', {
        'minute-important': minute.important,
        'bg-white': !minute.important,
      })}
      onMouseEnter={() => setDisplayActions(true)}
      onMouseLeave={() => setDisplayActions(false)}
    >
      <div className="flex flex-row sm:w-1/3 py-2 px-3">
        <div className="text-gray-700">{moment(minute.created_at).format('HH:mm:ss')}</div>
        <div className="pl-6 capitalize break-words font-semibold text-gray-800">{minute.who}</div>
      </div>
      <div className="flex flex-col w-full">
        <div
          className={'w-full break-words py-2 px-3'}
          dangerouslySetInnerHTML={{ __html: MARKDOWN.render(minute.what) }}
        />
        {minute.details && (
          <div className="flex">
            <div
              className="bg-blue-100 rounded border border-blue-400 text-blue-700 px-4 py-2"
              dangerouslySetInnerHTML={{ __html: MARKDOWN.render(minute.details) }}
            />
          </div>
        )}
      </div>
      <div
        className={classnames('items-center justify-center items-center p-2 absolute rounded', {
          flex: displayActions,
          hidden: !displayActions,
          'bg-red-100': minute.important,
          'bg-white': !minute.important,
        })}
        style={{ top: 0, right: 0, bottom: 0 }}
      >
        <button type="button" className="edit-icon-button mr-2" title="edit" onClick={() => onEdit(minute)}>
          <i className="fas fa-pen"></i>
        </button>
        <button type="button" className="edit-icon-button mr-2" title="important" onClick={handleMarkImportantClick}>
          <i className="fas fa-exclamation"></i>
        </button>
        <button type="button" className="edit-icon-button" title="delete" onClick={handleDestroyClick}>
          <i className="fas fa-trash"></i>
        </button>
      </div>
    </div>
  )
}

export default MinuteView
