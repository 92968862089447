import React, {useState} from 'react'

const CreateInvestigation = ({createInvestigation}) => {
  const [lead, setLead] = useState('')
  const [what, setWhat] = useState('')

  const createButtonClicked = () => {
    createInvestigation(lead, what)
    setLead('')
    setWhat('')
  }
  return (
    <tr>
      <td>
        <input id="lead" className="rounded" placeholder="Lead?" onChange={(e) => setLead(e.target.value)} value={lead}/>
      </td>
      <td className="w-full">
        <textarea id="what" className="rounded w-full" placeholder="What?" onChange={(e) => setWhat(e.target.value)} value={what}/>
      </td>
      <td colSpan="2">
        <button className="button bg-blue-600 hover:bg-blue-500 text-white" onClick={createButtonClicked}>
          Launch investigation
        </button>
      </td>
    </tr>
  )
}

export default CreateInvestigation