import React from 'react'

let hidden = null
let visibilityChange = null
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
  hidden = 'hidden'
  visibilityChange = 'visibilitychange'
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden'
  visibilityChange = 'msvisibilitychange'
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden'
  visibilityChange = 'webkitvisibilitychange'
}

const useVisibility = () => {
  const [visible, setVisible] = React.useState(true)
  React.useEffect(() => {
    const handleVisibilityChange = () => {
      setVisible(document[hidden] ? false : true)
    }
    document.addEventListener(visibilityChange, handleVisibilityChange, false)
    return () => {
      document.removeEventListener(visibilityChange, handleVisibilityChange)
    }
  }, [])

  return visible
}

export default useVisibility
