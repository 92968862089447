import React from 'react'
import classnames from 'classnames'

const Modal = ({ show, header, children, footer, onClose, large, isAlreadyFinished }) => {
    const escapePressed = ({ which, keyCode }) => {
        const key = which || keyCode
        if (key === 27 && !isAlreadyFinished) onClose()
    }

    React.useEffect(() => {
        document.addEventListener("keydown", escapePressed)

        return () => {
            document.removeEventListener("keydown", escapePressed)
        }
    }, [])

    if (!show) return null

    return (
        <div className="modal z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center">
            <div onClick={isAlreadyFinished ? undefined : onClose} className="modal-overlay absolute w-full h-full bg-gray-900 opacity-50"></div>
            <div className={classnames("modal-container bg-white mx-auto sm:rounded shadow-lg z-50 overflow-y-auto", { 'max-w-lg h-auto': !large })}>
                <div className="flex flex-col justify-between h-full py-4 text-left px-6">
                    <div className="flex justify-between items-center sm:pb-3">
                        <div className="text-2xl font-bold">{header}</div>
                    </div>

                    {children}

                    <div className="flex justify-end py-4 sm:py-2">
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal
