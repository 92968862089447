import React from "react"
import Modal from './Modal'
import Minute from "../modules/Minute"
import useDidUpdateEffect from 'hooks/useDidUpdateEffect'

const mapMinute = minute => {
  const { id, who, what, details, important, created_at } = minute
  return { id, who, what, details, important, created_at }
}

const ModalFooter = ({ onCancel, onSave, state }) => (
  <>
    <button className="button button-gray mr-1" onClick={onCancel}>
      Cancel
      </button>
    <button className="button button-blue" onClick={() => onSave(new Minute(state))}>
      Save Changes
      </button>
  </>
)

const MinuteEditPopin = ({ show, onClose, minute, onSave: onSaveProp, crisisId }) => {
  const [state, _setState] = React.useState({ who: '', what: '', details: '' })
  const setState = (fields) => _setState({
    ...state,
    ...fields,
  })

  const onCancel = () => {
    setState(mapMinute(minute))
    onClose()
  }

  useDidUpdateEffect(() => {
    setState(mapMinute(minute))
  }, [minute.id])

  const onSave = () => {
    let minute = new Minute({ ...mapMinute(state), crisis_id: crisisId })
    minute.update({
      who: state.who,
      what: state.what,
      details: state.details,
    })
    onSaveProp(minute)
    onClose()
  }

  return (
    <Modal show={show} header="Edit log line" onClose={onCancel} footer={<ModalFooter onCancel={onCancel} onSave={onSave} state={state} />}>
      <label className="py-2 m-0 font-semibold text-gray-700">Who?</label>
      <input
        className="border rounded p-2"
        style={{ width: '-webkit-fill-available' }}
        placeholder="Who?"
        type="text"
        value={state.who}
        name="who"
        onChange={(e) => setState({ who: e.target.value })} />
      <label className="py-2 m-0 font-semibold text-gray-700">What?</label>
      <textarea
        className="border rounded p-2"
        style={{ width: '-webkit-fill-available' }}
        placeholder="What?"
        value={state.what}
        name="what"
        onChange={(e) => setState({ what: e.target.value })} />
      <label className="py-2 m-0 font-semibold text-gray-700">Details</label>
      <textarea
        className="border rounded p-2"
        style={{ width: '-webkit-fill-available' }}
        value={state.details || ''}
        name="details"
        onChange={(e) => setState({ details: e.target.value })}
      >
      </textarea>
    </Modal>
  )
}

export default MinuteEditPopin
