import React from "react";
import classnames from "classnames";

const Warning = ({ title, message, className }) => (
  <div
    className={classnames(
      "bg-blue-100 border border-blue-500 text-blue-700 px-4 py-3 rounded",
      className
    )}
    role="alert"
  >
    <div className="font-bold mb-2">{title}</div>
    <div className="text-md">{message}</div>
  </div>
);

export default Warning;
