import React from "react";

const PostMortemMailLabel = (pmOwner) => {
  return (
    <div>
      <span className="text-sm italic mt-2 max-w-xl text-gray-700">
        The post-mortem will be sent to{" "}
        <span className="font-bold">{pmOwner.email}@doctolib.com</span>
        {pmOwner.team && pmOwner.domain ? (
          <span className="text-sm italic mt-2 max-w-xl text-gray-700">
            {" "}
            (team: {pmOwner.team}, domain: {pmOwner.domain})
          </span>
        ) : (
          <div>
            <span className="text-red-700 font-bold italic">Warning:</span>
            <span className="text-sm italic mt-2 max-w-xl text-gray-700">
              {" "}
              this doctoliber has no team or domain defined on{" "}
            </span>
            <a
              className="text-indigo-700"
              href="https://doctoteams.herokuapp.com/teammates"
              target="_blank"
            >
              Doctoteams
            </a>
            .
            <br />
            <span className="text-sm italic mt-2 max-w-xl text-gray-700">
              Please ask him/her to update these information and update DOWN
              ticket labels accordingly
            </span>
          </div>
        )}
      </span>
    </div>
  );
};

export default PostMortemMailLabel;
