import React, { useMemo, useContext } from 'react'
import moment from 'moment'
import classnames from 'classnames'
import AssignmentContext from "./AssignmentContext"

const NULL_MANAGER = { name: 'No one' }

const CalendarPlus = ({ className }) => (
  <svg className={className} class="icon" width="16" height="16" viewBox="0 0 16 16" fill="white">
    <path d="M5 2.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75v.75h3v-.75c0-.398.328-.75.75-.75.398 0 .75.352.75.75v.75h1.125c.61 0 1.125.516 1.125 1.125V5.75H2.75V4.625c0-.61.492-1.125 1.125-1.125H5v-.75Zm8.25 10.125c0 .633-.516 1.125-1.125 1.125h-8.25a1.11 1.11 0 0 1-1.125-1.125V6.5h10.5v6.375Zm-5.813-4.5v1.313H6.125a.555.555 0 0 0-.563.562c0 .328.235.563.563.563h1.313v1.312c0 .328.234.563.562.563a.555.555 0 0 0 .563-.563v-1.313h1.312a.555.555 0 0 0 .563-.562.57.57 0 0 0-.563-.563H8.562V8.376A.57.57 0 0 0 8 7.812a.555.555 0 0 0-.563.563Z"></path>
  </svg>
)

const shortenName = (string) => {
  if (!string) return string

  const parts = string.split(' ')
  const firstName = parts.shift()
  const firstNameParts = firstName.split('-')
  const capitals = firstNameParts.map((firstNamePart) => firstNamePart[0].toUpperCase())

  return `${capitals.join('.')}. ${parts.join(' ')}`
}

const AssignmentReloading = () => (
  <div className='assignment-line text-gray-800 font-normal cursor-pointer'>
    <span className='assignment-selected'>...</span>
  </div>
)

const Assignment = ({ assignment, assignee, kind, user, date }) => {
  const { assignementToSwitch, assignementToSwitchWith, setAssignmentToSwitch, onSwapAssignments } = useContext(AssignmentContext)

  const candidateForSwitch = assignementToSwitch?.kind === assignment?.kind && assignementToSwitch?.id !== assignment?.id && !assignementToSwitchWith
  const noAssignee = assignee.name === NULL_MANAGER.name

  const handleSwitchAssignment = () => {
    if (!assignment) return

    if (!assignementToSwitch)
      setAssignmentToSwitch(assignment)
    else if (candidateForSwitch) {
      onSwapAssignments(assignment)
    }
  }

  if (assignementToSwitchWith && [assignementToSwitch?.id, assignementToSwitchWith?.id].includes(assignment?.id)) {
    return <AssignmentReloading />
  }

  return (
    <div className="flex items-center">
      <div
        className={classnames('assignment-line text-gray-800 font-normal cursor-pointer', {
          'border border-solid border-red-500': candidateForSwitch,
        })}
        onClick={handleSwitchAssignment} >
        <span className="font-semibold">{kind} :</span>
        <span
          className={classnames({
            'text-red-700 font-semibold': noAssignee,
            'assignment-selected': assignementToSwitch?.id === assignment?.id && !noAssignee,
          })}
        >
          &nbsp;{noAssignee ? assignee.name : shortenName(assignee?.name)}
        </span>
      </div>
      {user?.id === assignee?.id && (
        <a
          className="bg-blue-500 flex hover:bg-blue-700 ml-2 p-1 rounded-full"
          href={`https://www.google.com/calendar/render?action=TEMPLATE&text=Crisis%20Incident%20${kind}&details=&location=&dates=${moment(
            date
          ).format('YYYYMMDD')}%2F${moment(date).add(1, 'day').format('YYYYMMDD')}`}
          target="_blank"
        >
          <CalendarPlus />
        </a>
      )}
    </div>
  )
}

const IncidentManagerScheduleCard = ({ date, managers, assignment, user }) => {
  const scheduleAssignment = assignment[0]
  const incidentManager = useMemo(() => {
    return managers.managers.find((manager) => manager.id === scheduleAssignment?.assignee_id) || NULL_MANAGER
  }, [scheduleAssignment?.assignee_id])

  const shadow = useMemo(() => {
    return managers.shadows.find((manager) => manager.id === scheduleAssignment?.shadow_id) || NULL_MANAGER
  }, [scheduleAssignment?.shadow_id])

  const noOneAssigned = [incidentManager, shadow].every((manager) => manager.name === NULL_MANAGER.name)

  return (
    <div className="assignment-card max-w-sm flex-col rounded items-center text-sm border-2 p-3 my-1 ">
      <div className="text-gray-900 leading-none font-semibold text-lg divb-2 flex justify-between">
        <span>{date}</span>
      </div>
      {noOneAssigned ? (
        <div className="text-gray-800 font-normal">
          No assignee for that day
        </div>
      ) : (
        <>
          <Assignment assignment={scheduleAssignment} assignee={incidentManager} kind="Manager" user={user} date={date} />
          <Assignment assignment={scheduleAssignment} assignee={shadow} kind="Shadow" user={user} date={date} />
        </>
      )}
    </div>
  )
}

export default IncidentManagerScheduleCard
