import React from 'react'

const useDidUpdateEffect = (cb, watched) => {
  const pastFirstRenderRef = React.useRef(false)

  React.useEffect(() => {
    if (pastFirstRenderRef.current) cb()
    else pastFirstRenderRef.current = true
  }, watched)
}

export default useDidUpdateEffect
