import React from 'react'
import classnames from 'classnames'
import IncidentManagerCard from './IncidentManagerCard'

const IncidentManagerList = ({ managers }) => {

  return (
    <div>
      <div className={classnames("flex flex-col")}>
        {managers.map(manager => <IncidentManagerCard key={manager.email} manager={manager} />)}
      </div>
    </div>
  )
}

export default IncidentManagerList
