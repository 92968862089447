const focus = (target) => {
  const node = document.getElementById(target)
  node.focus()
  node.select()
}

// I don't want to write an html escaping method,
// so using the browser textarea innerHTML ability.
var escape = document.createElement('textarea')

const escapeHTML = (html) => {
  escape.textContent = html
  return escape.innerHTML
}

const safeLinkHighlight = (text) => {
  let result = escapeHTML(text)
  let matches = result.match(/(https?|ftp|ssh|mailto):\/\/[a-z0-9\/:%_+.,#?!@&=-]+/gi)
  if (matches) {
    matches.forEach(
      (url) => { result = result.replace(url, `<a href="${url}" target="_blank">${url}</a>`) }
    )
  }
  return result
}

export const scrollTo = (target) => {
  document.getElementById(target).scrollIntoView({ behavior: 'smooth' })
}

function stripHtml(html){
   const tmp = document.createElement('div')
   tmp.innerHTML = html
   return tmp.textContent || tmp.innerText || ''
}

export { focus, escapeHTML, safeLinkHighlight, stripHtml }
