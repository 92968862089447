const csrfToken = () => {
  let meta = document.querySelector('meta[name="csrf-token"]')
  return meta ? meta.getAttribute('content') : null
}

const httpHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken()
  }
}

class Minute {
  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  destroy() {
    return fetch(`/crises/${this.crisis_id}/minutes/${this.id}`, {
      method: 'DELETE',
      headers: httpHeaders()
    })
  }

  update(attributes) {
    return fetch(`/crises/${this.crisis_id}/minutes/${this.id}`, {
      method: 'PATCH',
      headers: httpHeaders(),
      body: JSON.stringify(attributes)
    }).then(response => response.json())
  }

  static create(crisis_id, who, what, details) {
    return fetch(`/crises/${crisis_id}/minutes/`, {
      method: 'POST',
      headers: httpHeaders(),
      body: JSON.stringify({ who, what, details })
    })
      .then(response => response.json())
  }
}

export default Minute
