import React from 'react'
import Warning from '../Warning'
import Select from 'react-select'
import PostMortemMailLabel from '../PostmortemMailLabel'
import EmailNotValid from '../EmailNotValid'
import { emailWithoutDomain } from '../EndCrisisFlow'

const EHR_TEAMS = ['BEAR', 'MOOSE', 'DOPE', 'CAT', 'SEAL', 'CAPY', 'FIRE']

const EHR_STAFF_ENGINEERS = [
  'christophe.hamerling@doctolib.com',
  'nicolas.klarman@doctolib.com',
  'stefan.luthe@doctolib.com',
]

const getMandatoryReviewers = (team, reviewers) => {
  if (EHR_TEAMS.includes(team)) return reviewers.filter((reviewer) => EHR_STAFF_ENGINEERS.includes(reviewer.email))

  return []
}

const TeamOwnerStep = ({
  setState,
  teams,
  teammates,
  state: { team_owner, domain_owner, postmortem_owner_email },
  validOwnerEmail,
  reviewers,
}) => {
  const teamOptions = teams.map((team) => ({
    value: { ...team },
    label: team.name,
  }))
  const teammatesOptions = teammates.map((teammate) => ({
    value: { ...teammate },
    label: teammate.first_name + ' ' + teammate.last_name,
  }))

  const selectTeam = (team) => {
    setState({
      domain_owner: team.value.domain,
      team_owner: team.value.name,
      postmortem_owner_email: emailWithoutDomain(team.value.manager_email),
      mandatoryReviewers: getMandatoryReviewers(team.value.name, reviewers),
    })
  }

  const selectTeammate = (teammate) => {
    setState({
      domain_owner: teammate.value.domain,
      team_owner: teammate.value.team,
      postmortem_owner_email: emailWithoutDomain(teammate.value.email),
    })
  }

  return (
    <div>
      <Warning
        className="mb-4"
        title="Designate a postmortem owner"
        message={
          <ul className="pl-4 list-disc">
            <li>Designate a team which will be responsible to write the post mortem.</li>
            <li>Usually the team is the one responsible for the broken feature in the product breakdown.</li>
            <li>Or if the responsible commit has been found, assign it to this team.</li>
            <li>By default, the post mortem owner will be the manager. You can override it if needed.</li>
          </ul>
        }
      />
      <div className="flex flex-col">
        <span className="font-bold mb-2">Team</span>
        <div className="flex items-center justify-start">
          <Select
            className="w-full"
            inputId="teamOwner"
            options={teamOptions}
            isMulti={false}
            onChange={selectTeam}
            value={teamOptions.find((option) => option.label === team_owner)}
          />
        </div>
        <span className="font-bold mb-2">Email of post-mortem owner</span>
        <div className="flex items-center justify-center">
          <Select
            className="w-full"
            inputId="pm_owner_email"
            options={teammatesOptions}
            isMulti={false}
            onChange={selectTeammate}
            value={{
              label: teammatesOptions.find((option) => emailWithoutDomain(option.value.email) == postmortem_owner_email)
                ?.label,
              value: postmortem_owner_email,
            }}
          />
        </div>
        {postmortem_owner_email && validOwnerEmail ? (
          <PostMortemMailLabel email={postmortem_owner_email} team={team_owner} domain={domain_owner} />
        ) : (
          <span className="text-sm italic mt-2 max-w-xl text-gray-700">
            This field is mandatory, we will update the down ticket and assign it to the post-mortem owner. The field
            must <span className="text-red-700">only contain the username part</span> of the doctolib address
          </span>
        )}
        {!validOwnerEmail ? <EmailNotValid /> : null}
      </div>
    </div>
  )
}

export default TeamOwnerStep
