import React from "react";
import classname from "classnames";

const Caret = () => (
  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
    <svg
      className="fill-current h-4 w-4"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
    </svg>
  </div>
);

const Select = ({ id, className, collection, value, onChange, disabled }) => (
  <div className={classname("inline-block relative w-64", className)}>
    <select
      id={id}
      value={value}
      onChange={onChange}
      className="dc-select"
      disabled={disabled}
    >
      {collection.map(({ id, name }) => (
        <option value={id} key={id}>
          {name}
        </option>
      ))}
    </select>
    <Caret />
  </div>
);

export default Select;
