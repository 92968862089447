import React from 'react'
import Warning from '../Warning'

const ObviousTechTaskStep = () => (
  <Warning
    className="mb-4"
    title="Any obvious task?"
    message={
      <>
        <div>
          If you find any obvious task to fix, prevent or mitigate, please create a tech task for it right away on
          the&nbsp;
          <a
            href="https://doctolib.atlassian.net/secure/RapidBoard.jspa?rapidView=54&quickFilter=304"
            target="_blank"
            rel="noreferrer"
            className="font-semibold text-center underline text-blue-500"
          >
            Tech Tasks JIRA project
          </a>
          .
        </div>
        <div>Remember to link TTs to the down ticket and apply postmortem label.</div>
      </>
    }
  />
)

export default ObviousTechTaskStep
