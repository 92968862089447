import React from 'react'
import CrisisSelect from '../base/CrisisSelect'

const ReviewersStep = ({ state, setState, reviewers: reviewersProp, voluntaryReviewers }) => {
  const { reviewers = {}, mandatoryReviewers } = state
  const { first, second, third, fourth, fifth } = reviewers

  const filteredReviewersAgainst = (array) => reviewersProp.filter(({ id }) => !array.includes(`${id}`))

  const setReviewer = (reviewer) =>
    setState({
      reviewers: {
        ...reviewers,
        ...reviewer,
      },
    })

  return (
    <div className="flex flex-col max-w-2xl">
      <p className="text-xl pb-6">Post mortem reviewers</p>
      <p className="text-gray-800 pb-4">
        The two first reviewers are pre-selected as the Incident Manager and the Communication manager for the incident.
        The review of the Incident Manager is mandatory. The review of the Communication Manager is optional and depends
        on the incident impact.
      </p>
      <p>
        For the third reviewer, please select someone relevant in term of scope knowledge, which will understand the
        problem and be able to challenge the technical output of the post mortem.
      </p>
      <div className="flex flex-col sm:flex-row items-center justify-center">
        <CrisisSelect
          id="first"
          className="mb-2"
          collection={filteredReviewersAgainst([second, third, fourth, fifth])}
          value={first}
          onChange={(e) => setReviewer({ first: +e.target.value })}
        />
      </div>
      {[...voluntaryReviewers, ...mandatoryReviewers].length > 0 && (
        <p className="text-gray-800 py-4">
          Additional reviewers: {[...voluntaryReviewers, ...mandatoryReviewers].flatMap(({ name }) => name).join(', ')}
        </p>
      )}
    </div>
  )
}

export default ReviewersStep
