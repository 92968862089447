import React from "react"
import Minute from "../modules/Minute"
import { focus, scrollTo } from "../modules/Utility"

const MinuteEdit = ({ crisis_id, whos }) => {
  const [who, setWho] = React.useState('')
  const [what, setWhat] = React.useState('')
  const [notAtBottom, setNotAtBottom] = React.useState(false)
  const whatAreaRef = React.useRef(null)

  const handleWhoKeypress = (event) => {
    if (event.key == 'Enter') {
      event.preventDefault()
      focus('whatInput')
    }
  }

  const handleSubmitThroughKeyboard = (e) => {
    const code = e.keyCode ? e.keyCode : e.which
    if (code === 13 && !e.shiftKey) handleSubmit(e)
  }

  const handleNotAtBottom = () => {
    if (notAtBottom && (window.innerHeight + window.scrollY) >= document.body.scrollHeight)
      setNotAtBottom(false)
    else if (!notAtBottom)
      setNotAtBottom(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    if (!who || who.trim().length === 0) {
      window.alert('Please fill in "Who?" field')
      setWho('')
      return focus('whoInput')
    }
    if (!what || what.trim().length === 0) {
      window.alert('Please fill in "Said what?" field')
      setWhat('')
      return focus('whatInput')
    }

    Minute.create(crisis_id, who, what, '') // details
    setWhat('')
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleNotAtBottom)
    whatAreaRef.current.addEventListener("keydown", handleSubmitThroughKeyboard)

    return () => {
      window.removeEventListener("scroll", handleNotAtBottom)
      whatAreaRef.current.removeEventListener("keydown", handleSubmitThroughKeyboard)
    }
  })

  return (
    <form className="relative" onSubmit={handleSubmit}>
      {notAtBottom && <button type="button" className="edit-icon-button scroll-down-button" title="scroll to bottom" onClick={() => scrollTo('bottomScrollAnchor')}><i className="fas fa-angle-double-down"></i></button>}
      <div className="hidden sm:flex flex-col sm:flex-row py-2">
        <div className="w-full sm:w-1/5 bg-white rounded border">
          <input
            list="whos"
            className="w-full p-2 rounded focus:outline-none"
            id="whoInput"
            placeholder="Who?"
            type="text"
            value={who}
            name="who"
            onKeyPress={handleWhoKeypress}
            onChange={({ target: { value } }) => setWho(value)}
          />

          <datalist id="whos">
            {whos.map((who) => (<option value={who} key={who}/>))}
          </datalist>

        </div>
        <div className="w-full sm:w-4/5 bg-white rounded sm:mx-2 border">
          <textarea className="w-full p-2 rounded focus:outline-none"
            style={{ height: '40px', maxHeight: '40px', minHeight: '40px', resize: 'none' }}
            placeholder="Said what?"
            id="whatInput"
            ref={whatAreaRef}
            value={what}
            name="what"
            onChange={({ target: { value } }) => setWhat(value)}
          />
          <p className="text-gray-700 italic text-right text-xs px-2 pb-1">This field supports Markdown</p>
        </div>
        <button className="bg-blue-500 hover:bg-blue-700 text-white w-full sm:w-1/5 p-2 rounded" type="submit">Save log</button>
      </div>
    </form>
  )
}

export default MinuteEdit
