import React, { useEffect } from 'react'
import AssignmentContext from "./AssignmentContext"
import IncidentManagerScheduleCard from "./IncidentManagerScheduleCard"

export const crisisKindMapping = {
  app: 'Application',
  security: 'Security',
  salesforce: 'Salesforce',
  it: 'IT',
  ci: 'Continuous Integration',
  simulation: 'Simulation',
  pr: 'Public Relation',
  lgl: 'Legal',
  doc_sec: 'Doc Sec',
  ehr: 'EHR',
  dev_env: 'Developer Environment',
  data: 'Data',
  e2ee: 'E2EE',
}

const IncidentManagementSchedule = ({ managers, dutySchedule, crisisKind, user }) => {
  const [assignementToSwitch, setAssignmentToSwitch] = React.useState(null)
  const [assignementToSwitchWith, setAssignementToSwitchWith] = React.useState(null)

  const handleSwapAssignments = (assignementToSwitchWith) => setAssignementToSwitchWith(assignementToSwitchWith)

  useEffect(() => {
    if (!assignementToSwitchWith) return

    if (!window.confirm('Do you really want to swap selected incident manager shifts?')) {
      setAssignmentToSwitch(null)
      setAssignementToSwitchWith(null)
      return
    }

    const token = document.querySelector('meta[name="csrf-token"]')?.attributes?.content?.value

    fetch('/incident_management_assignments/swaps', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        from_assignment_id: assignementToSwitch.id,
        to_assignment_id: assignementToSwitchWith.id
      })
    }).then(() => window.location.reload())
  }, [assignementToSwitch, assignementToSwitchWith])

  return (
    <AssignmentContext.Provider value={{ assignementToSwitch, assignementToSwitchWith, setAssignmentToSwitch, onSwapAssignments: handleSwapAssignments }}>
      <div>
        <div className="text-sm leading-5 font-medium text-gray-600 pl-6 pt-6">{crisisKindMapping[crisisKind]} Incident Managers schedule</div>
        <div className="grid grid-cols-5 gap-1 m-2">
          {Object.keys(dutySchedule).map(date => {
            if ([0, 6].includes(new Date(date).getDay())) return null
            return <IncidentManagerScheduleCard key={date} date={date} managers={managers} assignment={dutySchedule[date]} user={user} />
          })}
        </div>
      </div>
    </AssignmentContext.Provider>
  )
}

export default IncidentManagementSchedule
