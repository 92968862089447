import React from 'react'
import classnames from 'classnames'
import { crisisKindMapping } from './IncidentManagementSchedule'

const Bell = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M8 14.4c.875 0 1.575-.7 1.575-1.6H6.4c0 .9.7 1.6 1.6 1.6zm5.375-3.725c-.475-.525-1.4-1.3-1.4-3.875 0-1.925-1.35-3.475-3.2-3.875V2.4c0-.425-.35-.8-.775-.8-.45 0-.8.375-.8.8v.525C5.35 3.325 4 4.875 4 6.8c0 2.575-.925 3.35-1.4 3.875a.641.641 0 00-.2.525c0 .425.3.8.8.8h9.575c.5 0 .8-.375.825-.8a.762.762 0 00-.225-.525z" />
  </svg>
)

const BellOn = ({ className }) => (
  <svg
    className={className}
    width="18"
    height="18"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
  >
    <path d="M2.8 6.4c0-.325-.275-.6-.6-.6H.6c-.35 0-.6.275-.6.6 0 .35.25.6.6.6h1.6c.325 0 .6-.25.6-.6zM1.275 3.225l1.4.8c.075.05.2.1.3.1.325 0 .6-.275.6-.6 0-.2-.15-.425-.3-.525l-1.4-.8a.71.71 0 00-.3-.1c-.325 0-.6.275-.6.6 0 .2.125.425.3.525zM13 4.1c.1 0 .225-.025.3-.075l1.4-.8a.68.68 0 00.275-.5c0-.35-.25-.6-.6-.6a.57.57 0 00-.275.075l-1.4.8c-.15.1-.3.325-.3.5 0 .35.275.6.6.6zM8 14.4c.875 0 1.6-.7 1.6-1.6H6.4c0 .9.7 1.6 1.6 1.6zm7.4-8.6h-1.6c-.35 0-.6.275-.6.6 0 .35.25.6.6.6h1.6c.325 0 .6-.25.6-.6 0-.325-.275-.6-.6-.6zm-3.425 1c0-1.925-1.35-3.475-3.2-3.875V2.4c0-.425-.35-.8-.8-.8-.425 0-.8.375-.8.8v.525C5.35 3.325 4 4.875 4 6.8c0 2.575-.925 3.35-1.4 3.875a.807.807 0 00-.2.525c0 .425.325.8.8.8h9.575c.5 0 .8-.375.825-.8 0-.175-.1-.4-.225-.525-.475-.525-1.4-1.3-1.4-3.875z" />
  </svg>
)

const activeLink = (current_path) => {
  let path
  if (typeof window === 'undefined') path = current_path
  else path = window.location.pathname

  if (Boolean(path.match(/crises\/[0-9]+$/)) || Boolean(path.match(/crises$/))) return 'history'
  else if (Boolean(path.match(/minutes/))) return 'scribe'
  else if (Boolean(path.match(/subscriptions/))) return 'subscriptions'
  else if (Boolean(path.match(/sms_campaign/))) return 'sms_campaign'
  else if (Boolean(path.match(/incident_managers/))) return 'incident_managers'
  else if (Boolean(path.match(/users/))) return 'users'
  return ''
}

const IncidentManagersDropDown = ({ active }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <div className="relative hidden sm:block">
      <button
        onClick={() => setOpen((prevOpen) => !prevOpen)}
        className={classnames(
          'ml-4 px-3 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
          {
            'bg-gray-900': active === 'incident_managers',
          },
        )}
      >
        Incident managers
      </button>
      {open && (
        <div>
          <button
            onClick={() => setOpen(false)}
            tabIndex="-1"
            className="fixed inset-0 h-full w-full cursor-default"
          ></button>
          <div className="origin-top-right absolute right-0 mt-2 w-48 rounded-lg shadow-lg">
            <div className="py-1 rounded-lg bg-white shadow-xs" role="menu">
              {Object.entries(crisisKindMapping)
                .sort((a, b) => (a[1] < b[1] ? -1 : 1))
                .map(([key, value]) => (
                  <a
                    key={key}
                    href={`/users/incident_managers?crisis_kind=${key}`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    {value}
                  </a>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const NavBar = ({ crisisOngoing, current_path, loggedIn, user }) => {
  const [open, setOpen] = React.useState(false)
  const active = activeLink(current_path)
  return (
    <div>
      <nav className="bg-gray-800 z-50">
        <div className="dc-container px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex items-center justify-center flex-shrink-0">
                <div className="relative h-8 w-8">
                  <Bell
                    className={classnames('centered-axis-xy', {
                      'text-red-400': crisisOngoing,
                      'text-white': !crisisOngoing,
                    })}
                  />
                  {crisisOngoing && <BellOn className="centered-axis-xy animate-flicker text-red-500" />}
                </div>
                <div className="px-2 text-white ">
                  <a href="/">Doctocrisis</a>
                </div>
              </div>
              <div className="hidden md:block">
                {loggedIn ? (
                  <div className="ml-10 flex items-center">
                    <a
                      href="/crises"
                      className={classnames(
                        'ml-4 px-3 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                        {
                          'bg-gray-900': active === 'history',
                        },
                      )}
                    >
                      History
                    </a>
                    <IncidentManagersDropDown active={active} user={user} />
                    <a
                      href="/users"
                      className={classnames(
                        'ml-4 px-3 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                        {
                          'bg-gray-900': active === 'users',
                        },
                      )}
                    >
                      Doctolibers
                    </a>
                    <a
                      href="/sms_campaign"
                      className={classnames(
                        'ml-4 px-3 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                        {
                          'bg-gray-900': active === 'sms_campaign',
                        },
                      )}
                    >
                      SMS Campaigns
                    </a>
                    <a
                      href="/subscriptions"
                      className={classnames(
                        'ml-4 px-3 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                        {
                          'bg-gray-900': active === 'subscriptions',
                        },
                      )}
                    >
                      SMS Subscriptions
                    </a>
                    <a
                      href="/finished_crises"
                      className={classnames(
                        'ml-4 px-3 py-2 rounded-lg text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700',
                        {
                          'bg-gray-900': active === 'finished_crisis',
                        },
                      )}
                    >
                      Finished incident
                    </a>
                  </div>
                ) : (
                  <a
                    id="sign_in"
                    href="/auth/google_oauth2"
                    data-method="post"
                    ref="nofollow"
                    className="px-3 py-2 rounded-lg text-sm font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
                  >
                    Sign or log in
                  </a>
                )}
              </div>
            </div>
            <div className="hidden md:block">
              <div className="ml-4 flex items-center md:ml-6">
                <div className="text-white text-xs">
                  <a href={`/users/${user.id}`}>
                    {user && user.name}
                    <img className="rounded-full mx-2 inline" width="25" height="25" src={user.picture} />
                  </a>
                </div>
                <a
                  href="/signout"
                  className="block p-1 border-2 border-transparent font-semibold rounded-full focus:outline-none sm:mt-0"
                >
                  <i className="fa fa-lock text-gray-500 hover:text-red-700 " />
                </a>
              </div>
            </div>
            <div className="-mr-2 flex md:hidden">
              <button className="inline-flex items-center justify-center p-2 rounded-lg text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white">
                <svg
                  className={classnames('h-6 w-6 block')}
                  onClick={() => setOpen((prevOpen) => !prevOpen)}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d={open ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'}
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div className={classnames('md:hidden', { block: open, hidden: !open })}>
          <div className="px-2 pt-2 pb-3 sm:px-3">
            {loggedIn ? (
              <>
                <a
                  href="/crises"
                  className="mt-1 block px-3 py-2 rounded-lg text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  History
                </a>
                {Object.entries(crisisKindMapping)
                  .sort((a, b) => (a[1] < b[1] ? -1 : 1))
                  .map(([key, value]) => (
                    <a
                      key={`mobile${key}`}
                      href={`/users/incident_managers?crisis_kind=${key}`}
                      className="mt-1 block px-3 py-2 rounded-lg text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                    >
                      {value}
                    </a>
                  ))}
                <a
                  href="/users"
                  className="mt-1 block px-3 py-2 rounded-lg text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  Doctolibers
                </a>
                <a
                  href="/sms_campaign"
                  className="mt-1 block px-3 py-2 rounded-lg text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  SMS campaigns
                </a>
                <a
                  href="/subscriptions"
                  className="mt-1 block px-3 py-2 rounded-lg text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
                >
                  SMS Subscriptions
                </a>
              </>
            ) : (
              <a id="sign_in" href="/auth/google_oauth2" className="dc-navbar-link">
                Sign or log in
              </a>
            )}
          </div>
          <div className="pt-4 pb-3 border-t border-gray-700">
            <div className="flex items-center px-5">
              <div className="flex-shrink-0">
                <div className="flex h-10 w-10 rounded-full bg-blue-500 items-center justify-center text-white font-bold">
                  {user && user.name && user.name.length > 0 && user.name[0]}
                </div>
              </div>
              <div className="ml-3">
                <div className="text-base font-medium leading-none text-white">{user && user.name}</div>
                <div className="mt-1 text-sm font-medium leading-none text-gray-400">{user && user.email}</div>
              </div>
            </div>
            <div className="mt-3 px-2">
              <a
                href="/signout"
                className="mt-1 block px-3 py-2 rounded-lg text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
              >
                Sign out
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  )
}

export default NavBar
