import React from 'react'
import classnames from 'classnames'
import IncidentManagerCard from "./IncidentManagerCard"

const IncidentManagerExpandableList = ({ managers }) => {
  const [opened, setOpened] = React.useState(false)

  return (
    <div>
      <button onClick={() => setOpened(prevOpened => !prevOpened)} className="button button-gray inline-flex items-center focus:outline-none">
        {opened ? 'Hide' : 'Show'} incident managers list
      </button>
      <div className={classnames("flex flex-col", { 'hidden': !opened })}>
        {managers.map(manager => <IncidentManagerCard key={manager.email} manager={manager} />)}
      </div>
    </div>
  )
}

export default IncidentManagerExpandableList
