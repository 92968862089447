import React from 'react'

const csrfToken = () => {
  let meta = document.querySelector('meta[name="csrf-token"]')
  return meta ? meta.getAttribute('content') : null
}

const httpHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken()
  }
}

const PingCommManager = ({crisisId}) => {
  const onPing = () => {
    fetch('/slack_notifier/ping_comm_manager', {
      method: 'POST',
      headers: httpHeaders(),
      body: JSON.stringify({crisis_id: crisisId}),
    }).then()
  }

  return (
      <div className="flex flex-row items-center gap-2">
        <button
          className="button bg-blue-600 hover:bg-blue-500 text-white"
          onClick={onPing}
        >
          Ask for communication manager
        </button>
      </div>
  )

}

export default PingCommManager