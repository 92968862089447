/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
import '../css/application.css'

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

window.Selectr = require('mobius1-selectr')
window.Noty = require('noty')
window.Noty.overrideDefaults({
    callbacks: {
        onTemplate: function() {
            const { type, text } = this.options
            const color = type === 'success' ? 'green' : type === 'alert' ? 'red' : 'blue'
            const icon = type === 'success' ? 'far fa-check-circle' :  type === 'alert' ? 'fas fa-exclamation-circle' : 'fas fa-info-circle'
            this.barDom.innerHTML = '\
            <div class="noty_body bg-white rounded-lg border p-3"> \
                <div class="flex justify-between">\
                    <div class="flex">\
                        <div class="flex">\
                            <i class="'+ icon + ' text-' + color + '-600 pt-1 pr-3 text-lg"></i>\
                        </div>\
                        <div class="flex-col">\
                            <h3 class="capitalize font-bold text-sm text-gray-800">' + type + '</h3>\
                            <p class="text-sm text-gray-700">' + text + '</p>\
                        </div>\
                    </div> \
                    <div class="flex">\
                        <i class="fas fa-times text-gray-500 pt-1 px-3 text-lg"></i>\
                    </div>\
                </div>\
            </div>'
        }
    }
})
