import React from 'react'

const IncidentManagerToolAction = ({label, children}) => (
  <tr>
    <td>
      <p className="text-black italic text-left text-normal px-2 pb-1">{label}</p>
    </td>
    <td style={{width: '75%'}}>{children}</td>
  </tr>
)

export default IncidentManagerToolAction