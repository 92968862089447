import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import axios from 'axios'
import classnames from 'classnames'
import Modal from './Modal'
import { debounce } from 'lodash'
import ReviewersStep from './steps/ReviewersStep'
import Warning from './Warning'
import ObviousTechTaskStep from './steps/ObviousTechTaskStep'
import TeamOwnerStep from './steps/TeamOwnerStep'
import PostMortemMailLabel from './PostmortemMailLabel'
import EmailNotValid from './EmailNotValid'

const SEVERITIES = [
  {
    label: 'SEV-1',
    value: 'super_high_crisis',
    previousLabel: '"Super" High Crisis',
    definition: 'Application is down. Full Outage for all users (or the majority of them)',
    example: 'The entire platform is down',
    mitigationSla: '5 min "ASAP"',
    mitigationHours: 'BH/NBH',
  },
  {
    label: 'SEV-2',
    value: 'high_crisis',
    previousLabel: 'High Crisis',
    definition: 'Availability is significantly restricted for a significant amount of user',
    example: 'Online booking is down',
    mitigationSla: '15 min "ASAP"',
    mitigationHours: 'BH/NBH',
  },
  {
    label: 'SEV-3',
    value: 'medium_crisis',
    previousLabel: 'Medium Crisis',
    definition: 'Application is available but a key feature is actively impacting many users',
    example: 'Patient search is down for a large number of organisations',
    mitigationSla: '30 min',
    mitigationHours: 'BH',
  },
  {
    label: 'SEV-4',
    value: 'high_bug',
    previousLabel: 'High Bug',
    definition: 'Application is available but a feature is impacting a few portion of users',
    example: '57 patients were not able to reset their password',
    mitigationSla: '1 week',
    mitigationHours: 'BH',
  },
  {
    label: 'SEV-5',
    value: 'bug',
    previousLabel: 'Bug',
    definition: 'Issue affecting a feature within a specific condition and impacting a limited set of users',
    example: 'Impossible to pay a TCS with a new credit card for a relative',
    mitigationSla: '1 month',
    mitigationHours: 'BH',
  },
  {
    label: 'Not an incident',
    value: 'not_a_crisis',
    previousLabel: '',
    definition: '',
    example: '',
    mitigationSla: '',
    mitigationHours: '',
  },
]

const CATEGORIES = [
  { label: 'Applicative', value: 'applicative' },
  { label: 'Infra', value: 'infra' },
  { label: 'Human Error', value: 'human_error' },
  { label: 'External Provider', value: 'external_provider' },
  { label: 'Not an incident', value: 'not_a_crisis' },
  { label: 'Duplicate', value: 'duplicate' },
]

const LGL_CATEGORIES = [
  {
    label: 'Data Protection Authorities',
    value: 'Data Protection Authorities',
  },
  { label: 'Competition Authorities', value: 'Competition Authorities' },
  { label: 'Tax authorities', value: 'Tax authorities' },
  { label: "Bailiff 'huissier'", value: "Bailiff 'huissier'" },
  { label: 'Other authorities', value: 'Other authorities' },
  { label: 'Human error', value: 'Human error' },
  { label: 'Not a crisis', value: 'Not a crisis' },
]

const APP_DETECTION_SOURCES = [
  { label: 'Internal User', value: '11720' },
  { label: 'External User', value: '11719' },
  { label: 'Alerting or Monitoring', value: '11721' },
]

const APP_IMPACTED_PRODUCTS = [
  { label: 'All Doctolib products', value: '11722' },
  { label: 'Patient services', value: '17006' },
  { label: 'Doctolib Patients (PRM)', value: '11723' },
  { label: 'Doctolib Practice (EHR)', value: '11725' },
  { label: 'Doctolib Telehealth', value: '11726' },
  { label: 'Doctolib Team', value: '11727' },
  { label: 'Internal Tools (Slack, gmail, CI etc.)', value: '11728' },
  { label: 'Not sure', value: '11729' },
]

const APP_MITIGATION = [
  { label: 'Rollback', value: '11730' },
  { label: 'Hot Fix', value: '11731' },
  { label: 'DevOps intervention', value: '11735' },
  { label: 'IT intervention', value: '11736' },
  { label: '3rd party intervention', value: '11733' },
  { label: 'Solved by itself', value: '11744' },
  { label: 'Feature switch change', value: '11732' },
  { label: 'Security Intervention', value: '11745' },
  { label: 'Other', value: '11734' },
]

const NOT_A_CRISIS_CATEGORY = [
  { label: 'False alarm business', value: 'false_alarm_business' },
  { label: 'False alarm monitoring', value: 'false_alarm_monitoring' },
  { label: 'False alarm traffic spike', value: 'false_alarm_traffic_spike' },
  { label: 'Duplicate', value: 'duplicate' },
]

const SeverityStep = ({ state: { severity }, setState }) => (
  <>
    <table>
      <thead>
        <tr>
          <th className="p-2 text-center">Incident severity?</th>
          <th className="p-2 text-center">Previously</th>
          <th className="p-2">Definition</th>
          <th className="p-2 text-center">Mitigation SLA</th>
        </tr>
      </thead>
      <tbody>
        {SEVERITIES.map(({ label, value, previousLabel, definition, example, mitigationSla, mitigationHours }) => (
          <>
            <tr>
              <td className="p-2">
                <button
                  key={value}
                  className={classnames('button w-full mb-1 button-gray focus:outline-none', {
                    'border-2 border-blue-600': value === severity,
                  })}
                  onClick={() => setState({ severity: value })}
                >
                  <div>{label}</div>
                </button>
              </td>
              <td className="p-2 text-center">
                <p className="text-sm">{previousLabel || '-'}</p>
              </td>
              <td className="p-2">
                <p className="text-sm text-wrap">{definition || '-'}</p>
                {example && <p className="text-sm text-wrap italic">Eg: {example}</p>}
              </td>
              <td className="p-2 text-center">
                <p className="text-sm">{mitigationSla || '-'}</p>
                {mitigationHours && <p className="text-sm">{mitigationHours}</p>}
              </td>
            </tr>
          </>
        ))}
      </tbody>
    </table>
  </>
)

const CategoryStep = ({ state, setState }) => {
  if (state['severity'] == 'not_a_crisis') {
    return (
      <>
        <p className="text-xl pb-6">Pick the corresponding category for this false alarm below</p>
        {NOT_A_CRISIS_CATEGORY.map(({ label, value }) => (
          <button
            key={value}
            className={classnames('button mb-1 button-gray focus:outline-none', {
              'border-2 border-blue-600': value === state['not_a_crisis_category'],
            })}
            onClick={() => setState({ not_a_crisis_category: value })}
          >
            {label}
          </button>
        ))}
      </>
    )
  } else if (state['kind'] == 'lgl') {
    return (
      <>
        <p className="text-xl pb-6">Pick the corresponding category for this incident below</p>
        {LGL_CATEGORIES.map(({ label, value }) => (
          <button
            key={value}
            className={classnames('button mb-1 button-gray focus:outline-none', {
              'border-2 border-blue-600': value === state['category'],
            })}
            onClick={() => setState({ category: value })}
          >
            {label}
          </button>
        ))}
      </>
    )
  } else {
    return (
      <>
        <p className="text-xl pb-6">Pick the corresponding category for this incident below</p>
        {CATEGORIES.map(({ label, value }) => (
          <button
            key={value}
            className={classnames('button mb-1 button-gray focus:outline-none', {
              'border-2 border-blue-600': value === state['category'],
            })}
            onClick={() => setState({ category: value })}
          >
            {label}
          </button>
        ))}
      </>
    )
  }
}

const AppDetectionStep = ({ state, setState }) => {
  return (
    <>
      <p className="text-xl pb-6">What or who detected the incident?</p>
      {APP_DETECTION_SOURCES.map(({ label, value }) => (
        <button
          key={value}
          className={classnames('button mb-1 button-gray focus:outline-none', {
            'border-2 border-blue-600': value === state['detection'],
          })}
          onClick={() => setState({ detection: value })}
        >
          <div>{label}</div>
        </button>
      ))}
    </>
  )
}

const AppImpactedProductsStep = ({ state, setState }) => {
  return (
    <>
      <p className="text-xl pb-6">Select the product most impacted by this incident:</p>
      {APP_IMPACTED_PRODUCTS.map(({ label, value }) => (
        <button
          key={value}
          className={classnames('button mb-1 button-gray focus:outline-none', {
            'border-2 border-blue-600': value === state['impacted'],
          })}
          onClick={() => setState({ impacted: value })}
        >
          <div>{label}</div>
        </button>
      ))}
    </>
  )
}

const AppMitigationStep = ({ state, setState }) => {
  return (
    <>
      <p className="text-xl pb-6">How was the incident mitigated or solved?</p>
      {APP_MITIGATION.map(({ label, value }) => (
        <button
          key={value}
          className={classnames('button mb-1 button-gray focus:outline-none', {
            'border-2 border-blue-600': value === state['mitigation'],
          })}
          onClick={() => setState({ mitigation: value })}
        >
          <div>{label}</div>
        </button>
      ))}
    </>
  )
}

export const emailWithoutDomain = (email) => {
  return email?.slice(0, email.indexOf('@'))
}

const PostMortemOwnerStep = ({
  teammates,
  state: { postmortem_owner_email, team_owner, domain_owner },
  setState,
  validOwnerEmail,
}) => {
  const teammatesOptions = teammates.map((teammate) => ({
    value: { ...teammate },
    label: teammate.first_name + ' ' + teammate.last_name,
  }))

  const selectTeammate = (teammate) => {
    setState({
      domain_owner: teammate.value.domain,
      team_owner: teammate.value.team,
      postmortem_owner_email: emailWithoutDomain(teammate.value.email),
    })
  }

  return (
    <div>
      <Warning
        className="mb-4"
        title="Designate a postmortem owner"
        message={
          <ul className="pl-4 list-disc">
            <li>
              Delegate the post-mortem to someone with the right tech knowledge of the issue (possibly someone in the
              incident)
            </li>
            <li>Confirm with that she/he can write and publish the post-mortem within 5 business days</li>
            <li>The Post-Mortem Owner email must be a user in Jira if this is a PRODUCT incident.</li>
          </ul>
        }
      />
      <div className="flex flex-col">
        <span className="font-bold mb-2">Email of post-mortem owner</span>
        <div className="flex items-center justify-center">
          <Select
            className="w-full"
            inputId="pm_owner_email"
            options={teammatesOptions}
            isMulti={false}
            onChange={selectTeammate}
            value={{
              label: teammatesOptions.find((option) => emailWithoutDomain(option.value.email) == postmortem_owner_email)
                ?.label,
              value: postmortem_owner_email,
            }}
          />
        </div>
        {postmortem_owner_email && validOwnerEmail ? (
          <PostMortemMailLabel email={postmortem_owner_email} team={team_owner} domain={domain_owner} />
        ) : (
          <span className="text-sm italic mt-2 max-w-xl text-gray-700">
            This field is mandatory, we will update the down ticket and assign it to the post-mortem owner. The field
            must <span className="text-red-700">only contain the username part</span> of the doctolib address
          </span>
        )}
        {!validOwnerEmail ? <EmailNotValid /> : null}
      </div>
    </div>
  )
}

const NotesStep = ({ state: { notes }, setState }) => (
  <>
    <Warning
      className="mb-4"
      title="Prepare postmortem while there are still reachable people"
      message={
        <>
          <div>Make sure that we have every information we will need to complete this post-mortem.</div>
          <div>
            Take advantage of the fact that there is still people in the hangout or on the slack channel to answer
            related questions.
          </div>
          <ul className="pl-4 list-disc">
            <li>5 whys?</li>
            <li>Calls DX? (datadog)</li>
            <li>Client impact? (datadog)</li>
            <li>Why did such or such action took so long?</li>
            <li>What can or can't we do about it?</li>
            <li>What's important to remember for the postmortem?</li>
            <li>What's still unclear and need to be answer during postmortem?</li>
          </ul>
          <div>Report any information in the field below</div>
        </>
      }
    />
    <div className="w-full">
      <textarea
        style={{ minHeight: '200px' }}
        name="notes"
        value={notes}
        onChange={(e) => setState({ notes: e.target.value })}
        placeholder="Write your notes here"
        className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-blue-500"
      />
    </div>
  </>
)

const Footer = ({
  stepIndex,
  stepKeys,
  setStepIndex,
  onClose,
  endCrisis,
  submitValid,
  isAlreadyFinished,
  crisisId,
}) => {
  const handleCancelClick = (crisisId) => {
    const tokenSelector = document.querySelector('meta[name="csrf-token"]')
    const token = tokenSelector && tokenSelector.getAttribute('content')

    axios
      .delete(`/finished_crises/${crisisId}`, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': token,
        },
      })
      .then(() => (window.location.href = '/finished_crises'))
  }

  return (
    <div className="flex w-full justify-between">
      {stepIndex > 0 ? (
        <button onClick={() => setStepIndex(stepIndex - 1)} className="text-sm sm:px-4 text-indigo-500 p-3">
          <span className="hidden sm:block">{'< Previous'}</span>
          <span className="block sm:hidden">{'<<'}</span>
        </button>
      ) : (
        <div />
      )}
      <div>
        <button
          onClick={() => (isAlreadyFinished ? handleCancelClick(crisisId) : onClose())}
          className="text-sm sm:px-4 bg-transparent p-3 rounded-lg text-indigo-500 hover:bg-gray-100 hover:text-indigo-400 mr-2"
        >
          Cancel
        </button>
        {stepIndex < stepKeys.length - 1 ? (
          <button
            id="continue"
            disabled={!submitValid}
            onClick={() => setStepIndex(stepIndex + 1)}
            className="continue sm:px-4 bg-indigo-500 p-3 rounded-lg text-white hover:bg-indigo-400"
          >
            Continue
          </button>
        ) : (
          <button
            onClick={endCrisis}
            className="button shadow uppercase bg-green-500 hover:bg-green-700 text-white font-semibold py-2 cursor-pointer px-4 border border-gray-400 rounded"
          >
            end the incident
          </button>
        )}
      </div>
    </div>
  )
}

const Steps = (kind, canSelectTeam) => {
  return {
    severity: SeverityStep,
    category: CategoryStep,
    ...(['simulation', 'app'].includes(kind)
      ? { appDetection: AppDetectionStep, appImpacted: AppImpactedProductsStep, appMitigation: AppMitigationStep }
      : {}),
    ...(kind !== 'ehr' && canSelectTeam && { teamOwner: TeamOwnerStep }),
    ...(kind !== 'ehr' && !canSelectTeam && { postmortemOwner: PostMortemOwnerStep }),
    notes: NotesStep,
    ...(kind !== 'ehr' && { reviewers: ReviewersStep }),
    final: ObviousTechTaskStep,
  }
}

const StepsKeys = (kind, canSelectTeam) => Object.keys(Steps(kind, canSelectTeam))

const EndCrisisModal = ({
  show,
  onClose,
  reviewers,
  state,
  setState,
  endCrisis,
  kind,
  teams,
  teammates,
  voluntaryReviewers,
  isAlreadyFinished,
  crisisId,
}) => {
  const [submitValid, setSubmitValid] = useState(true)
  const [stepIndex, setStepIndex] = useState(0)
  const stepKeys = StepsKeys(kind, teams && teams.length > 0)
  const preSteps = Steps(kind, teams && teams.length > 0)
  const StepComponent = preSteps[stepKeys[stepIndex]]
  const { postmortem_owner_email } = state
  const { notes } = state
  const pmOwnerKey = stepKeys.indexOf('postmortemOwner')
  const teamOwnerKey = stepKeys.indexOf('teamOwner')
  const notesKey = stepKeys.indexOf('notes')
  const [validOwnerEmail, setValidOwnerEmail] = useState(true)

  const validatePostMortemOwnerEmail = (post_mortem_owner_email) => {
    const tokenSelector = document.querySelector('meta[name="csrf-token"]')
    const token = tokenSelector && tokenSelector.getAttribute('content')
    axios
      .post(
        '/validate_postmortem_owners',
        { post_mortem_owner_email },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        },
      )
      .then(function (response) {
        setValidOwnerEmail(response.data.valid_email)
        setSubmitValid(response.data.valid_email)
      })
      .catch((error) => console.error(error))
  }

  const debouncedSearch = React.useRef(
    debounce(async (postmortem_owner_email) => {
      validatePostMortemOwnerEmail(postmortem_owner_email)
    }, 300),
  ).current

  useEffect(() => {
    if (stepIndex === pmOwnerKey || stepIndex === teamOwnerKey) {
      if (kind === 'app') {
        if (postmortem_owner_email.length > 2) {
          debouncedSearch(postmortem_owner_email)
          setSubmitValid(validOwnerEmail)
        } else {
          setSubmitValid(false)
        }
      } else if (!postmortem_owner_email.length || postmortem_owner_email.includes('@')) {
        setSubmitValid(false)
      } else setSubmitValid(true)
    } else if (stepIndex === notesKey && notes.length < 0) setSubmitValid(false)
    else setSubmitValid(true)
  }, [stepIndex, postmortem_owner_email, notes, submitValid])

  return (
    <Modal
      show={show}
      header={
        <div className="flex flex-col text-gray-800">
          <div id="end-crisis-modal">End the incident</div>
          <div className="font-normal text-sm text-gray-700 italic pb-3">
            The incident is not effectively ended until the steps are completed. {`${stepIndex + 1}/${stepKeys.length}`}
          </div>
        </div>
      }
      footer={
        <Footer
          submitValid={submitValid}
          stepIndex={stepIndex}
          setStepIndex={setStepIndex}
          stepKeys={stepKeys}
          onClose={onClose}
          endCrisis={endCrisis}
          isAlreadyFinished={isAlreadyFinished}
          crisisId={crisisId}
        />
      }
      onClose={onClose}
      large
      isAlreadyFinished={isAlreadyFinished}
    >
      <div className="flex flex-col mx-auto text-gray-800">
        <StepComponent
          state={state}
          setState={setState}
          reviewers={reviewers}
          voluntaryReviewers={voluntaryReviewers}
          setSubmitValid={setSubmitValid}
          stepIndex={stepIndex}
          validOwnerEmail={validOwnerEmail}
          teams={teams}
          teammates={teammates}
          kind={kind}
        />
      </div>
    </Modal>
  )
}

const CheckMark = ({ className }) => (
  <svg
    width="16"
    height="16"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    viewBox="0 0 16 16"
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.947 12.585l-4.16-4.16a.64.64 0 010-.905l.906-.905a.64.64 0 01.905 0L6.4 9.417l6.002-6.002a.64.64 0 01.905 0l.906.905a.64.64 0 010 .905l-7.36 7.36a.64.64 0 01-.906 0z"
    />
  </svg>
)

const EndCrisisFlow = ({
  crisisId,
  currentStatus,
  kind,
  reviewers = [],
  selectedReviewers,
  severity: severityProp,
  featureTeams,
  teammates,
  notes,
  isAlreadyFinished = false,
}) => {
  const [state, _setState] = useState({
    severity: severityProp || 'medium_crisis',
    postmortem_owner_email: '',
    reviewers:
      kind !== 'ehr'
        ? {
            first: (selectedReviewers && selectedReviewers[0].id) || reviewers[0].id,
          }
        : {},
    notes: '',
    kind,
    mitigation: '',
    impacted: '',
    detection: '',
    mandatoryReviewers: [],
  })
  const [show, _setShow] = useState(isAlreadyFinished)

  const voluntaryReviewers = selectedReviewers?.slice(1)

  const setShow = (newShow) => {
    const token = document.querySelector('meta[name="csrf-token"]')?.attributes?.content?.value

    fetch(`/crises/${crisisId}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        status: newShow ? 'closing' : currentStatus,
        no_redirect: true,
      }),
    }).then()
    _setShow(newShow)
  }

  const setState = (newState) =>
    _setState((prevState) => ({
      ...prevState,
      ...newState,
    }))

  const endCrisis = () => {
    const tokenSelector = document.querySelector('meta[name="csrf-token"]')
    const token = tokenSelector && tokenSelector.getAttribute('content')
    const end_of_crisis_data = {
      ...state,
      reviewers: [
        state.reviewers.first,
        ...voluntaryReviewers.map(({ id }) => id),
        ...state.mandatoryReviewers.map(({ id }) => id),
      ],
    }

    axios
      .post(
        `/crises/${crisisId}/end`,
        { end_of_crisis_data },
        {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': token,
          },
        },
      )
      .then((res) => (document.location = '/'))
      .catch((error) => console.error(error))
  }

  return (
    <div>
      <button onClick={() => setShow(true)} className="flex action-crisis-button bg-green-600 hover:bg-green-500">
        <CheckMark className="mr-2" />
        End incident
      </button>
      <EndCrisisModal
        endCrisis={endCrisis}
        state={state}
        setState={setState}
        show={show}
        onClose={() => setShow(false)}
        reviewers={reviewers}
        voluntaryReviewers={voluntaryReviewers}
        kind={kind}
        teams={featureTeams}
        teammates={teammates}
        isAlreadyFinished={isAlreadyFinished}
        crisisId={crisisId}
      />
    </div>
  )
}

export default EndCrisisFlow
