import React, {useEffect, useState} from 'react'
import CreateInvestigation from "./CreateInvestigation";
import Investigation from "./Investigation";

const csrfToken = () => {
  let meta = document.querySelector('meta[name="csrf-token"]')
  return meta ? meta.getAttribute('content') : null
}

const httpHeaders = () => {
  return {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrfToken()
  }
}

const InvestigationsList = ({crisisId}) => {
  const [investigations, setInvestigations] = useState([])
  const [loading, setLoading] = useState(false)

  const createInvestigation = (lead, what) => {
    fetch('/investigations', {
      method: 'POST',
      headers: httpHeaders(),
      body: JSON.stringify({ lead, what, crisis_id: crisisId })
    })
      .then((response) => response.json())
      .then((createdInvestigation) =>
        setInvestigations((prevState) => [...prevState, createdInvestigation])
      )

  }

  const closeInvestigation = (investigationId) => {
    fetch(`/investigations/${investigationId}`, {
      method: 'PUT',
      headers: httpHeaders(),
      body: JSON.stringify({close: true})
    })
      .then((response) => response.json())
      .then((closedInvestigation) => {
      setInvestigations(
        (currentInvestigations) =>
          [...currentInvestigations.filter(({id}) => id !== investigationId), closedInvestigation])
    })
  }

  const updateInvestigation = (investigationId, lead, what) => {
    fetch(`/investigations/${investigationId}`, {
      method: 'PUT',
      headers: httpHeaders(),
      body: JSON.stringify({lead, what})
    })
      .then((response) => response.json())
      .then((updatedInvestigation) => {
      setInvestigations(
        (currentInvestigations) =>
          [...currentInvestigations.filter(({id}) => id !== investigationId), updatedInvestigation])
    })
  }

  const fetchInvestigations = () => {
    setLoading(true)
    fetch(`/investigations?crisis_id=${crisisId}`, {
      method: 'GET',
      headers: httpHeaders()
    })
      .then((response) => response.json())
      .then((data) => {
      setLoading(false)
      setInvestigations(data)
    })
  }

  useEffect(() => {
    fetchInvestigations()
  }, [])

  return (
    <div>
      <table>
        <tbody>
          {investigations.sort((a, b) => new Date(a.created_at) - new Date(b.created_at)).map(({id, lead, what, created_at, closed_at}) =>
            <Investigation
              key={id}
              id={id}
              lead={lead}
              what={what}
              createdAt={created_at}
              closedAt={closed_at}
              closeInvestigation={closeInvestigation}/>)
          }
          <CreateInvestigation createInvestigation={createInvestigation}/>
        </tbody>
      </table>
    </div>
  )
}

export default InvestigationsList