import React, {useEffect, useState} from 'react'

const Investigation = ({lead, what, createdAt, closedAt, closeInvestigation, id}) => {
  const [timeEllapsed, setTimeEllapsed] = useState((closedAt ? new Date(closedAt) : new Date()) - new Date(createdAt))

  useEffect(() => {
    if (closedAt) {
      setTimeEllapsed(new Date(closedAt) - new Date(createdAt))
      return
    }
    const intervalId = setInterval(() => setTimeEllapsed(prevTime => prevTime + 1000), 1000)
    return () => clearInterval(intervalId)
  }, [closedAt])

  const textClassNames = closedAt ?
    'text-gray-600 italic text-left text-normal px-2 pb-1' :
    'text-black text-left text-normal px-2 pb-1'


  return (
    <tr className="border">
      <td>
        <p className={textClassNames}>{lead}</p>
      </td>
      <td className="w-full">
        <p className={textClassNames}>{what}</p>
      </td>
      <td>
        <p className={textClassNames}>{new Date(timeEllapsed).toISOString().substring(11, 19)}</p>
      </td>
      <td>
        {closedAt ? (
          <p className={textClassNames}>Done</p>
        ) : (
            <button className="button bg-blue-600 hover:bg-blue-500 text-white" onClick={() => closeInvestigation(id)}>
              Close
            </button>
        )}
      </td>
    </tr>
  )
}

export default Investigation